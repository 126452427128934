<template>
    <feather-icon
      icon="PrinterIcon"
      size="16"
      class="hover-green"
      @click="print"
    />
</template>
<script>
import { jsPDF } from "jspdf";
import { ProcessTypesEnum, SortTypesEnum } from '@/data/enums';
import autoTable from 'jspdf-autotable'

import ggA_ElbeSaale from "../../../../../assets/images/pdf/ggA_ElbeSaale.png";
import ggA_Hallertau from "../../../../../assets/images/pdf/ggA_Hallertau.png";
import ggA_Spalt from "../../../../../assets/images/pdf/ggA_Spalt.png";
import ggA_Tettnang from "../../../../../assets/images/pdf/ggA_Tettnang.png";
import { costFormatter,secondWeightFormatter, percentFormatter, secondCostFormatter, weightFormatter } from '@/utility/format/formatter';

export default {
    components:{},
    props:{
        row: {
            type: Object,
            required: true,
        },
        process: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        costFormatter,
        secondCostFormatter,
        percentFormatter,
        weightFormatter,
        secondWeightFormatter,
        ProcessTypesEnum,
        SortTypesEnum,
        ggA_ElbeSaale,
        ggA_Hallertau,
        ggA_Spalt,
        ggA_Tettnang
    }),

    methods: {
        async print(row){
            const translationsRoot = "Management.Certificates.Print";
            const doc = new jsPDF();
            const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            const center = pageWidth / 2;

            let german = true;
            let thirdCountry = false;
            this.process.areas.forEach(area => {
                if(area.country.short !== "DE") german = false;
            });
            this.process.areas.forEach(area => {
                if(area.country.isThirdCountry){
                    thirdCountry = true;
                    german = false;
                }
            });

            // Certificate Header
            doc.setFontSize(24);
            doc.text(this.$t(`${translationsRoot}.Header`), center, 36,"center");
            doc.setFontSize(9);
            doc.text(this.$t(`${translationsRoot}.Subheader.1`), center, 42, "center");
            doc.text(this.$t(`${translationsRoot}.Subheader.2`), center, 46, "center");
            doc.text(this.$t(`${translationsRoot}.Subheader.3`), center, 50, "center");

            doc.setFontSize(12);
            doc.text(this.$t(`${translationsRoot}.NoSeeds`), center, german ? 77 : 68, "center");

            doc.setFontSize(14);
            let lupulin = ""
            if(this.process.processType === this.ProcessTypesEnum.VA){
                if(this.process.sortType === this.SortTypesEnum.Lupulinpellets){
                    lupulin = this.$t(`${translationsRoot}.Types.Lupulin`) + " ";
                }
            }
            doc.text(lupulin + this.$t(`${translationsRoot}.Types.${this.process.processType === this.ProcessTypesEnum.VA ? "VA": "Leerung"}`), 
            center, german ? 85 : 75, "center");

            let printCountries = false;

            if(this.process.sealDistrict?.name){
                doc.setFontSize(12);
                doc.text(this.$t(`${translationsRoot}.SealDistrict`) + this.process.sealDistrict.name, center, 100, "center");
            } else {
                // Certificate Countries
                if(!german){
                    doc.setFontSize(16);
                    doc.text(this.$t(`${translationsRoot}.Countries`), center - 5, 83, {align:"center", charSpace:1});
                    printCountries = true;
                }
            }

            // Certificate Areas
            doc.setFontSize(16);
            doc.text(this.$t(`${translationsRoot}.Areas`), center - (this.$t(`${translationsRoot}.Areas`).length / 2), 112, {align:"center", charSpace:1});
            
            doc.setFontSize(12);


            doc.setFontSize(24);
            if(german){
                doc.text(this.$tc(`${translationsRoot}.Title`, 1), center, 60, "center");
                doc.text(this.$tc(`${translationsRoot}.Title`, 2), center, 70, "center");
            } else if(thirdCountry) {
                doc.setFontSize(20);
                doc.text(this.$tc(`${translationsRoot}.ThirdCountry`, 1), center, 56, "center");
                doc.text(this.$tc(`${translationsRoot}.ThirdCountry`, 2), center, 63, "center");
            } else{
                doc.text(this.$tc(`${translationsRoot}.Title`, 2), center, 60, "center");
            }


            // Certificate HarvestYear
            doc.setFontSize(16);
            doc.text(this.$t(`${translationsRoot}.HarvestYear`), center - (this.$t(`${translationsRoot}.HarvestYear`).length  / 2), 142 , {align:"center", charSpace:1});
            doc.setFontSize(12);
            doc.text(this.process.harvestYear, center, 149, "center");

            // Certificate Sorts
            const sorts = [];
            const groupArr = [];
            let groups = "";

            this.process.sorts.forEach(sort => {
                if(groupArr.findIndex(group => group === sort.group) === -1){
                    groupArr.push(sort.group);
                }
            });
            for (let index = 0; index < groupArr.length; index++) {
                groups += groupArr[index] == "Sonstige" && groupArr.length === 3 ? "Sonst." : groupArr[index];
                if(index + 1 < groupArr.length){
                    groups += ", "
                }
                
            }
            
            // Areas
            let areas = [];
            let countries = [];
            let plainCountries = [];
            const areaCols = Math.ceil(this.process.areas.length / 3);
            let areaCellWidthFactor = 4;
            let areaCell = pageWidth / areaCellWidthFactor;

            for (let index = 1; index < areaCols; index++) {
                areaCellWidthFactor+=2
                cell = pageWidth / areaCellWidthFactor;
            }
            if(areaCols === 1){
                areaCellWidthFactor = 3;
                areaCell = pageWidth / areaCellWidthFactor;
            }
            if(printCountries){
                for (let index = 0; index < this.process.areas.length; index++) {
                    if(plainCountries.findIndex(country => country === this.process.areas[index].country.name) === -1){
                        if(this.process.areas[index].country.name){
                            plainCountries.push(this.process.areas[index].country.name);
                        }
                    }
                }
                for (let index = 0; index < plainCountries.length; index++) {
                if(index + 2 < plainCountries.length && areaCols > 2){
                        countries.push(
                            [
                                plainCountries[index] !== undefined ? `${plainCountries[index]}`: '',
                                plainCountries[index+1] !== undefined ? `${plainCountries[index+1]}`: '',
                                plainCountries[index+2] !== undefined ? `${plainCountries[index+2]}`: '',

                            ]
                        )

                    index+=2;
                } else if(index + 1 < this.process.areas.length && areaCols > 1){
                        countries.push(
                            [
                                plainCountries[index] !== undefined ? `${plainCountries[index]}`: '',
                                plainCountries[index+1] !== undefined ? `${plainCountries[index+1]}`: '',
                            ]
                        )
                    index++;
                }
                else {
                    countries.push([ plainCountries[index] !== undefined ? `${plainCountries[index]}`: '']);
                }
            }
            }

            for (let index = 0; index < this.process.areas.length; index++) {
                if(index + 2 < this.process.areas.length && areaCols > 2){
                    areas.push(
                        [
                            `${countries.length > 1 ? `${this.process.areas[index].country.short} - ` : ""}${this.process.areas[index].name}`,
                            `${countries.length > 1 ? `${this.process.areas[index+1].country.short} - ` : ""}${this.process.areas[index+1].name}`,
                            `${countries.length > 1 ? `${this.process.areas[index+2].country.short} - ` : ""}${this.process.areas[index+2].name}`,
                        ]);
                    index+=2;
                } else if(index + 1 < this.process.areas.length && areaCols > 1){
                    areas.push(
                        [
                            `${countries.length > 1 ?  `${this.process.areas[index].country.short} - ` : ""}${this.process.areas[index].name}`,
                            `${countries.length > 1 ?  `${this.process.areas[index+1].country.short} - ` : ""}${this.process.areas[index+1].name}`,
                        ]);
                    index++;
                }
                else {
                    areas.push([`${countries.length > 1 ?  `${this.process.areas[index].country.short} - ` : ""}${this.process.areas[index].name}`]);
                }
            }

            let areaColumnStyle1 = { fontSize: 11, halign: "center"};
            let areaColumnStyle2 = { fontSize: 11, halign: "center"};
            let areaColumnStyle3 = { fontSize: 11, halign: "center"};

            if(this.process.areas.length === 4 || this.process.areas.length === 5){
                areaColumnStyle1 = { fontSize: 11, halign: "right", cellPadding:{left: 5, right: 5}};
                areaColumnStyle2 = { fontSize: 11, halign: "left", cellPadding:{left: 5, right: 5}};
                areaColumnStyle3 = { fontSize: 11, halign: "center"};
            }

            let countriesColumnStyle1 = { fontSize: 11, halign: "center"};
            let countriesColumnStyle2 = { fontSize: 11, halign: "center"};
            let countriesColumnStyle3 = { fontSize: 11, halign: "center"};

            if(plainCountries.length === 4 || plainCountries.length === 5){
                countriesColumnStyle1 = { fontSize: 11, halign: "right", cellPadding:{left: 5, right: 5}};
                countriesColumnStyle2 = { fontSize: 11, halign: "left", cellPadding:{left: 5, right: 5}};
                countriesColumnStyle3 = { fontSize: 11, halign: "center"};
            }

            // const areaColumnStyleRight = { fontSize: 11, halign: "right", cellPadding:{left: 5, right: 5}, cellWidth: 35};
            // const areaColumnStyleLeft = { fontSize: 11, halign: "left", cellPadding:{left: 5, right: 5}, cellWidth: 35};
            autoTable(doc, {
                startY: 115,
                body: areas,
                head: header,
                theme:"plain",
                margin: {left: areaCellWidthFactor === 8 ? areaCell + 10 : areaCell, right: areaCellWidthFactor === 8 ? areaCell + 10 : areaCell},
                styles: {
                    cellPadding: {top: 1.2, bottom: 1.2}
                },
                columnStyles: { 0: areaColumnStyle1, 
                                1: areaColumnStyle2,
                                2: areaColumnStyle3},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        data.cell.styles.halign = "center";
                        data.cell.styles.fontStyle = "normal";
                    }   
                }
            });

            if(printCountries){
                autoTable(doc, {
                startY: 86,
                body: countries,
                head: header,
                theme:"plain",
                margin: {left: areaCellWidthFactor === 8 ? areaCell + 10 : areaCell, right: areaCellWidthFactor === 8 ? areaCell + 10 : areaCell},
                styles: {
                    cellPadding: {top: 1.2, bottom: 1.2}
                },
                columnStyles: { 0: countriesColumnStyle1, 
                                1: countriesColumnStyle2,
                                2: countriesColumnStyle3},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        data.cell.styles.halign = "center";
                        data.cell.styles.fontStyle = "normal";
                    }   
                }
            });
            }

            // Sorts
            // let sorts = [];
            const sortCols = Math.ceil(this.process.sorts.length / 3) <= 3 ? Math.ceil(this.process.sorts.length / 3) : 3;
            const header = [[this.$tc(`${translationsRoot}.SortsTableHeader`, 1), this.$tc(`${translationsRoot}.SortsTableHeader`, 2)]];
            let cellWidthFactor = 4;
            let cell = pageWidth / cellWidthFactor;

            for (let index = 1; index < sortCols; index++) {
                cellWidthFactor+=2
                cell = pageWidth / cellWidthFactor;
                header[0].push(this.$tc(`${translationsRoot}.SortsTableHeader`, 1));
                header[0].push(this.$tc(`${translationsRoot}.SortsTableHeader`, 2));
            }

            for (let index = 0; index < this.process.sorts.length; index++) {
                if(index + 2 < this.process.sorts.length && sortCols > 2){
                    sorts.push(
                        [
                            this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`,
                            this.process.sorts[index+1].name, `${this.percentFormatter.format(this.process.sorts[index+1].sortPercentage)} % v.H.`,
                            this.process.sorts[index+2].name, `${this.percentFormatter.format(this.process.sorts[index+2].sortPercentage)} % v.H.`
                        ]);
                    index+=2;
                } else if(index + 1 < this.process.sorts.length && sortCols > 1){
                    sorts.push(
                        [
                            this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`,
                            this.process.sorts[index+1].name, `${this.percentFormatter.format(this.process.sorts[index+1].sortPercentage)} % v.H.`
                        ]);
                    index++;
                }
                else{
                    sorts.push([this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`]);
                }
            }

            let columnStyle1 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let columnStyle2 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let columnStyle3 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let columnStyle4 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let columnStyle5 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let columnStyle6 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell};
            let left = cell
            let right = cell

            if(sortCols === 2){
                columnStyle1 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell+4};
                columnStyle2 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell+4};
                columnStyle3 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell+4};
                columnStyle4 = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", cellWidth: cell+4};
                left = 30
                right = 20
            }

            autoTable(doc, {
                startY: 155,
                body: sorts,
                head: header,
                theme:"plain",
                margin: {left: left, right: right},
                styles: {
                    cellPadding: {top: 1.2, bottom: 1.2}
                },
                columnStyles: { 0: columnStyle1, 
                                1: columnStyle2,
                                2: columnStyle3,
                                3: columnStyle4,
                                4: columnStyle5,
                                5: columnStyle6},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        data.cell.styles.halign = "center";
                        data.cell.styles.fontStyle = "bold";
                        switch(sortCols){
                            case 3:  data.cell.styles.fontSize = 7;break;
                            case 2:  data.cell.styles.fontSize = 9;break;
                            case 1:  data.cell.styles.fontSize = 11;break;
                            default: data.cell.styles.fontSize = 11;break;
                        }
                    }   
                }
            });
            
            let processIds = this.getProcessIds();
            const upperBody = [];
            if(this.process.processType === this.ProcessTypesEnum.VA){
                upperBody.push([
                    {content: this.$tc(`${translationsRoot}.Group`, sorts.length > 1 ? 2 : 1), styles: { fontSize: 12, cellWidth: 25 }}, 
                    {content: groups, styles: { fontSize: 11, cellWidth: 40 , halign: "right" }}]);
            } 

            autoTable(doc, {
                margin: { right: center + 15, left: 30 },
                startY: 195,
                body: upperBody,
                theme:"plain",
                styles: {
                    cellPadding: {top: 0,right:0, bottom:0, left: 0}
                }
            });
            const body = [];
            body.push([
                { content: this.$t(`${translationsRoot}.ANZPACK`) , styles: { fontSize: 12, cellWidth: 40 }}, 
                {content: this.row.productId, styles: { fontSize: 11, cellWidth: 25 , halign: "right" }}
            ]);

            body.push([
                {content: this.$t(`${translationsRoot}.BEZUG`), styles: { fontSize: 12, cellWidth: 25 }},
                {content: this.row.processes[0].processId, styles: { fontSize: 12, cellWidth: 25 , halign: "right", fontStyle: 'bold' }}
            ]);
            for (let index = 1; index < processIds.length; index++) {
                body.push(
                    [
                        {content: ""}, 
                        {content: processIds[index], styles: {fontStyle: "normal", halign: 'right'}}, 
                    ],
                )
            }

            autoTable(doc, {
                margin: { right: center + 16, left: 30 },
                startY: this.process.processType === ProcessTypesEnum.VA ? 200 : 195,
                body: body,
                theme:"plain",
                styles: {
                    cellPadding: {top: 0,right:0, bottom:0, left: 0}
                }
            });

            doc.setFontSize(12);    
            doc.text(this.$t(`${translationsRoot}.Weight`), pageWidth - 32, 200, "right");
            doc.text(this.weightFormatter.format(this.row.weight), pageWidth - 32, 207, "right");

            // Footer
            doc.setFontSize(14);    
            doc.text(this.$t(`${translationsRoot}.PlaceAndDate`) + new Date(this.row.date).toLocaleDateString("fr-CH"), pageWidth - 32, 242, "right");

            doc.setFontSize(10);
            doc.text(this.$tc(`${translationsRoot}.Signed`, 1), pageWidth - 32, 251, "right");
            doc.text(this.$tc(`${translationsRoot}.Signed`, 2), pageWidth - 32, 255, "right");

            let shortYear = '';
            if (this.process.harvestYear && this.process.harvestYear.length === 4) {
                shortYear = this.process.harvestYear.charAt(2) + this.process.harvestYear.charAt(3);
            }
            
            doc.setFontSize(12);
            doc.text(`NR.: 032 DE / ${shortYear} ${this.row.certificateId}`, 30, 274.5);
            doc.setFontSize(10);
            if(this.process.processType === ProcessTypesEnum.VA && this.row.stockId !== undefined && this.row.orderId !== undefined){
                doc.text(`${this.row.stockId ? this.row.stockId : '' } / ${this.row.orderId ? this.row.orderId : ''}`, 30, 278.5);
            } else if(this.process.processType === ProcessTypesEnum.Leerung)  {
                doc.text(`${this.row.packingOrderId ? this.row.packingOrderId : ''} / ${this.row.orderId ? this.row.orderId : ''}`, 30, 278.5);
            }

            let printGga = null;
            switch(this.process.gga?.name){
                case "Elbe Saale": printGga = this.ggA_ElbeSaale;
                    break;
                case "Hallertau": printGga = this.ggA_Hallertau;
                    break;
                case "Spalt": printGga = this.ggA_Spalt;
                    break;
                case "Tettnang": printGga = this.ggA_Tettnang;
                    break;
                default: 
                    break;
            }

            if(printGga){
                doc.addImage({imageData: printGga, x: center-9, y: 205, width: 30, height: 60, format: "PNG"})
            }


            doc.addPage();
            doc.setFontSize(9);

            doc.text(this.$t(`${translationsRoot}.Charge`) + `   ${this.secondCostFormatter.format(this.row.costs)}`, center-3, 275, "center");
            window.open(doc.output('bloburl'), '_blank'); 
        },

        getProcessIds() {
            let ids = [];
            this.row.portions.forEach(process => {
                ids.push(process.processId);
            })
            return ids;
        }
    }
}
</script>